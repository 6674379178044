<template lang="pug">
div(v-if='Citas').pa-0
    v-container(fluid).pa-0 
        v-img(  height='470px'  :src='Citas.portada'  ).d-none.d-md-block
        v-img(  :src='Citas.portada' ).d-md-none
    //- Urologias
    .d-none.d-md-block
        v-row.justify-center.align-start(style="margin-top: 50px")
            v-row.justify-center.align-center.cajon
                
                v-card(  style="  border-radius: 15px; width: 60%").pa-3.elevation-1
                    v-row.justify-center.align-center
                        v-col(v-for='{icono, titulo,subtitulo,boton}, i in Contacto.urologias' :key='i' cols='3' )
                            

                            v-img(  :src='icono'  height='80' contain)
                            
                            h3(v-html='titulo').text-center.titulo
                            h2(v-html='subtitulo' ).text-center.subtitulo

                            .fila-boton

                                v-btn(rel="stylesheet" href="/servicios").elevation-0
                                 
                                    h3.white--text( v-html='boton' style="text-transform: capitalize; ").font-italic 
    //- IMAGEN TITULO
    v-row.justify-center.align-center.titulo_imagen
        v-img(  height='100' :src='Citas.titulo' contain ).d-none.d-md-block
        v-img(  height='85' :src='Citas.titulo' contain ).d-md-none.mt-5
    //- FORMULARIO
    
    .d-none.d-md-block
        v-row.justify-center.align-center.mt-12.mb-12.pb-12
            v-card().card
                v-row.justify-start.align-center 

                    v-col(cols=12 md=6)
                        v-img(  :src='Citas.imagen_citas'  contain).imagen_borde
                    v-col(cols=12 md=5)
                        v-form(v-model='validación').ml-10
                            v-text-field(placeholder='* Nombres y Apellidos:' v-model='información["Nombre: "]' )
                            v-text-field(placeholder='* Correo electrónico:' v-model='información["Correo: "]' )
                            v-text-field(placeholder='* Teléfono:' v-model='información["Celular: "]'  )
                            v-select(v-model='medico'  :items='transformarObjetoaArray(Citas.medicos)' :item-text='item=>item.nombre' item-value='nombre' label='Doctores' persistent-hint='' single-line='')
                            v-select(v-if='medico.length>0' v-model='horario_de_medico' :items='rutina' label='Selecciona horario')
                            .fila-boton.justify-center.align-items-center
                                v-btn(@click='enviar()' :loading='enviando_correo'  color='#00AA7F')
                                    h3.white--text(style="text-transform: capitalize; ").font-italic  Enviar
    .d-md-none 
        v-row.justify-center.align-center.mt-12.mb-12.pb-12
            v-card(style="width: 80%; border-radius: 15px 15px 15px 15px")
                v-carousel(hide-delimiters='' cycle interval='3000' :show-arrows="false"  height="100%")
                    v-carousel-item(v-for='{imagen}, i in Citas.banners_citas' :key='i' cover=''   )
                        v-img(  :src='imagen'  contain style=" border-radius: 15px 15px  ")                        
                                

                    
               
                v-col(cols=12 )
                    v-form(v-model='validación')
                        v-text-field(placeholder='* Nombres y Apellidos:' v-model='información["Nombre: "]' )
                        v-text-field(placeholder='* Correo electrónico:' v-model='información["Correo: "]' )
                        v-text-field(placeholder='* Teléfono:' v-model='información["Celular: "]')
                        v-select(v-model='medico'  :items='Citas.medicos' :item-text='item=>item.nombre' item-value='nombre' label='Doctores' persistent-hint='' single-line='')
                        v-select(v-if='medico.length>0' v-model='horario_de_medico' :items='rutina' label='Selecciona horario')
                        .fila-boton.justify-center.align-items-center.pb-5
                            v-btn(@click='enviar()' :loading='enviando_correo'  color='#00AA7F')
                                h3.white--text(style="text-transform: capitalize; ").font-italic  Enviar


</template>
<script>
import { mapActions, mapState } from 'vuex'
    export default {
        data: ()=>({
            información: {},
            enviando_correo: false,
            validación: true,
            medico: "",
            horario_de_medico: ""
        }),
        methods: {
            ...mapActions({
                enviar_correo_electronico: 'Contacto/enviar_correo_electronico',
                activar_snackbar: 'Ayudas/activar_snackbar',
            }),
            transformarObjetoaArray(objeto) {
                if(typeof objeto === 'object') return Object.keys(objeto).map(key=>objeto[key])
                else if(Array.isArray(objeto)) return objeto
                else return []
            },
            enviar(){
                const titulo = 'Información del contacto'
                const asunto = `Nuevo contacto`
                let cuerpo = `
                <table>`
                Object.keys(this.información).map( i => cuerpo+='<tr><td>' + i + '</td><td>' + this.información[i] + '</td></tr>')
                if(this.medico) cuerpo = cuerpo+= `<tr style="padding-right: 2px"><td>Médico: </td><td>${this.medico}</td></tr><br>`
                if(this.horario_de_medico) cuerpo = cuerpo+= `<tr><td>Horario: </td><td>${this.horario_de_medico}</td></tr>`

                cuerpo += `</table>`
                const configuracion_de_correo = {
                        auth: {
                            user: 'admin@uroavanza.com',
                            pass: 'Uroavanza_123'
                        },
                        port: 465,
                        secure: true,
                        host: 'smtp.zoho.com' 
                }
                const correo_electronico = 'informes@uroavanza.com'
                this.enviando_correo = true
                this.enviar_correo_electronico({titulo, cuerpo, asunto, correo_electronico, configuracion_de_correo})
                .then( () => {
                    this.activar_snackbar('Gracias por agendar. Lo contactaremos pronto.')
                    this.enviando_correo = false
                    this.información  = {}
                })
            },
        },
        computed: {
            ...mapState({
                Reglas: ({Ayudas})=>Ayudas.reglas,
            }),
            rutina(){{
                return this.Citas.medicos.find(item=>item.nombre === this.medico).horario || []
            }}
        }
    }
</script>
<style lang="sass" scoped>
.imagen_borde
    border-radius: 15px 0 0 15px 
    display: flex
    flex-direction: column
 
.card
    width: 60%
  
    border-radius: 15px
.fila-boton
    padding-top: 10px
    display: flex
    justify-content: center
    border-radius: 15px
    .v-btn  
        background-color: #00AA7F
        color: white
.cajon
  position: relative
  margin-top: -6%
  z-index: 1
  width: 100em
  
.titulo
    color: #00AA7F
    line-height: 1em
    font-size: 15px
.subtitulo
    color: #490383
    line-height: 1%
    font-size: 30px
.titulo_imagen
    margin-top: 5%
        
</style>